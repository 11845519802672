import { useContext } from "react";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import { SidebarContext } from "../../contexts/SidebarContext";
import Logo from "../Logo";

import { Box, Drawer, Hidden } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import SidebarMenu from "../SidebarMenu";

const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
            border-top-right-radius: ${theme.general.borderRadius};
            border-bottom-right-radius: ${theme.general.borderRadius};
        }
`
);

const TopSection = experimentalStyled(Box)(
  ({ theme }) => `
        display: flex;
        height: 60px;
        align-items: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper className="hide-print">
          <CustomScrollbars>
            <TopSection>{/* <Logo /> */}</TopSection>
            <SidebarMenu />
          </CustomScrollbars>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper className="hide-print">
            <CustomScrollbars>
              <TopSection></TopSection>
              <SidebarMenu />
            </CustomScrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
