import { ReactNode } from "react";
import { ReactComponent as MotorsIcon } from "./motorsIcons.svg";
import { ReactComponent as TicketsIcon } from "./ticketIcons.svg";
import { ReactComponent as MonarchIcon } from "./MonARCH.svg";
import { ReactComponent as AssetIcon } from "./assetIcons.svg";
import { ReactComponent as MappingIcon } from "./mapping.svg";
import { ReactComponent as PoolIcon } from "./pool.svg";
import { ReactComponent as TypeIcon } from "./type.svg";
import { ReactComponent as UseCaseIcon } from "./useCaseIcons.svg";
import { ReactComponent as UseCaseType } from "./useCaseType.svg";
import { ReactComponent as FacilityIcon } from "./facility.svg";
import { ReactComponent as LocationIcon } from "./location.svg";
import { ReactComponent as ManagementIcon } from "./management.svg";
import { ReactComponent as AlertsIcon } from "./alerts.svg";
import { env } from "../../env";
import store from "../../Redux/store";

export interface MenuItem {
  item_code?: string;
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
  heading?: string;
}

export interface MenuItems {
  items: MenuItem[];
}

const menuItemListAdmin: any = [
  {
    items: [
      {
        name: "Locations",
        link: "",
        icon: LocationIcon,
        heading: true,
      },

      {
        name: "Facilities",
        link: "/locations/list",
        icon: FacilityIcon,
      },
    ],
  },
  {
    items: [
      // { name: "Assets", icon: AssetIcon, link: "/assets" },
      {
        name: "Management",
        icon: ManagementIcon,
        link: "",
        heading: true,
      },
      {
        name: "Mapping",
        link: "/asset-management/mapping",
        icon: MappingIcon,
      },
      {
        name: "Pool",
        link: "/asset-management/pool",
        icon: PoolIcon,
      },
      // {
      //   name: "Usecase",
      //   link: "/asset-management/usecase",
      //   icon: UseCaseType,
      // },
      {
        name: "Types",
        link: "/asset-management/types",
        icon: TypeIcon,
      },
    ],
  },
  {
    items: [
      {
        name: "Alerts",
        link: "",
        icon: AlertsIcon,
        heading: true,
      },
      {
        name: "Tickets",
        link: "/maintenance/tickets",
        icon: TicketsIcon,
      },
    ],
  },
  {
    items: [
      {
        name: "",
        icon: MonarchIcon,
        link: `${env.monarch_app_url}`,
        heading: false,
      },
    ],
  },

  // {
  //   heading: "User",
  //   items: [
  //     {
  //       name: "Account Settings",
  //       icon: PersonIcon,
  //       link: "/management/profile",
  //     },
  //   ],
  // },
];

const menuItemListNonAdmin = [
  {
    items: [
      {
        name: "Locations",
        link: "",
        icon: LocationIcon,
        heading: true,
      },
      {
        name: "Facilities",
        link: "/locations/list",
        icon: FacilityIcon,
      },
    ],
  },
  {
    items: [
      {
        name: "Alerts",
        link: "",
        icon: AlertsIcon,
        heading: true,
      },
      {
        name: "Tickets",
        link: "/maintenance/tickets",
        icon: TicketsIcon,
      },
    ],
  },
  {
    items: [
      {
        name: "",
        icon: MonarchIcon,
        link: `${env.monarch_app_url}`,
        heading: false,
      },
    ],
  },

  // {
  //   heading: "User",
  //   items: [
  //     {
  //       name: "Account Settings",
  //       icon: PersonIcon,
  //       link: "/management/profile",
  //     },
  //   ],
  // },
];

const menuItemListDeveloper: any = [
  {
    items: [
      // { name: "Assets", icon: AssetIcon, link: "/assets" },
      {
        name: "Management",
        icon: ManagementIcon,
        link: "",
        heading: true,
      },
      {
        name: "Mapping",
        link: "/asset-management/mapping",
        icon: MappingIcon,
      },
      {
        name: "Pool",
        link: "/asset-management/pool",
        icon: PoolIcon,
      },
      {
        name: "Types",
        link: "/asset-management/types",
        icon: TypeIcon,
      },
    ],
  },
  {
    items: [
      {
        name: "Alerts",
        link: "",
        icon: AlertsIcon,
        heading: true,
      },
      {
        name: "Tickets",
        link: "/maintenance/tickets",
        icon: TicketsIcon,
      },
    ],
  },
  {
    items: [
      {
        name: "",
        icon: MonarchIcon,
        link: `${env.monarch_app_url}`,
        heading: false,
      },
    ],
  },
];

const menuItemListNoRole: any[] = [
  // {
  //   heading: "User",
  //   items: [
  //     {
  //       name: "Account Settings",
  //       icon: PersonIcon,
  //       link: "/management/profile",
  //     },
  //   ],
  // },
];

export {
  menuItemListAdmin,
  menuItemListNonAdmin,
  menuItemListNoRole,
  menuItemListDeveloper,
};
